var locale = $("html").attr("lang");

$(document).ready(function() {
    $.fn.dataTable.ext.order.intl = function(locales, options) {
        if (window.Intl) {
            var collator = new window.Intl.Collator(locales, options);
            var types = $.fn.dataTable.ext.type;
            delete types.order["string-pre"];
            types.order["string-asc"] = collator.compare;
            types.order["string-desc"] = function(a, b) {
                return collator.compare(a, b) * -1;
            };
        }
    };
    $.fn.dataTable.ext.order.intl("hu");
    $(".horses-list").DataTable({
        paginate: false,
        lengthChange: false,
        filter: true,
        sort: false,
        info: true,
        autoWidth: true,
        ordering: true,
        order: [[8, "desc"]],
        language: {
            url: "/modules/core/js/vendor/datatables/" + locale + ".json"
        }
    });
    $(".horse-list").DataTable({
        paginate: false,
        lengthChange: false,
        filter: false,
        sort: true,
        info: false,
        autoWidth: true,
        ordering: true,
        order: [[0, "desc"]],
        language: {
            url: "/modules/core/js/vendor/datatables/" + locale + ".json"
        }
    });
    $(".allrace-list").DataTable({
        paginate: false,
        lengthChange: false,
        filter: false,
        sort: true,
        info: false,
        autoWidth: true,
        ordering: true,
        order: [[9, "desc"]],
        language: {
            url: "/modules/core/js/vendor/datatables/" + locale + ".json"
        }
    });
    $(".archive-list").DataTable({
        paginate: false,
        lengthChange: false,
        filter: false,
        sort: true,
        info: false,
        autoWidth: true,
        ordering: true,
        order: [[0, "asc"]],
        language: {
            url: "/modules/core/js/vendor/datatables/" + locale + ".json"
        }
    });
    $(".btn-tab-selector").on("click", function(e) {
        e.preventDefault();

        $(".btn-tab-selector").each(function() {
            $(this).removeClass("active");
        });
        $(".tab-wrapper").each(function() {
            $(this).removeClass("active");
        });

        $(this).addClass("active");
        var targetTabSelector = $(this).data("target");
        $("." + targetTabSelector).addClass("active");
    });

    $(".scrolltofixed-container .mobile-trigger")
        .not(".active")
        .on("click", function(event) {
            event.stopPropagation();
            $(this).toggleClass("active");
            $(".scrolltofixed-container .scrolltofixed").toggleClass("open");
            if ($(this).hasClass("active")) {
                var triggerWidth = $(this).outerWidth();
                var scrolltofixedWidth = $(
                    ".scrolltofixed-container .scrolltofixed"
                ).outerWidth();
                var sumWidth = triggerWidth + scrolltofixedWidth;
                $(".scrolltofixed-container").css("width", scrolltofixedWidth + "px");
                var height = 0;
                $(".scrolltofixed .list-group-item").each(function() {
                    height += $(this).outerHeight();
                });
                $(".scrolltofixed").css({
                    height: height + "px"
                });
                triggerWidth = 0;
                scrolltofixedWidth = 0;
                sumWidth = 0;
            } else {
                var triggerWidth = $(this).outerWidth();
                $(".scrolltofixed-container").css("width", triggerWidth + "px");
                triggerWidth = 0;
            }
        });

    $(".list-group-item").on("click", function() {
        if ($(".scrolltofixed-container .scrolltofixed").hasClass("open")) {
            $(".scrolltofixed-container .mobile-trigger").removeClass("active");
            $(".scrolltofixed-container .scrolltofixed").removeClass("open");
            $(".scrolltofixed-container").css(
                "width",
                $(".scrolltofixed-container .mobile-trigger").outerWidth() + "px"
            );
            $(".scrolltofixed-container").css("height", "38px");
        }
    });

    $("body").on("click", function(event) {
        if (
            $(".scrolltofixed-container .mobile-trigger").hasClass("active") &&
            event.target != $(".list-group.open .list-group-item")
        ) {
            $(".scrolltofixed-container .mobile-trigger").removeClass("active");
            $(".scrolltofixed-container .scrolltofixed").removeClass("open");
            $(".scrolltofixed-container").css(
                "width",
                $(".scrolltofixed-container .mobile-trigger").outerWidth() + "px"
            );
            $(".scrolltofixed-container").css("height", "38px");
        }
    });

    // STICKY
    if (window.matchMedia("(max-width: 992px)").matches) {
        $(window).scroll(function(event) {
            var y = $(this).scrollTop();
            if (y >= 275) $(".scrolltofixed-container").addClass("fixed");
            else $(".scrolltofixed-container").removeClass("fixed");
        });
    }

    // DOWNLOADABLE LINKS
    if ($("a.downloadable").length) {
        $("a.downloadable").each(function() {
            if (!$(this).hasClass("build")) {
                var validExtensions = ["zip", "doc", "docx", "pdf"];
                var fileName = $(this).attr("href");
                var titleText = $(this).text();
                $(this).empty();
                var descText = $(this).attr("title");
                if (descText.length == 0) {
                    descText = "A file-hoz nem elérhető leírás.";
                }
                var fileExtension = fileName.substr(fileName.lastIndexOf(".") + 1);
                if ($.inArray(fileExtension, validExtensions) !== -1) {
                    if (fileExtension == "docx") {
                        fileExtension = "doc";
                    }
                    switch (fileExtension) {
                        case "zip":
                            var faClass = "fa-file-archive-o";
                            break;
                        case "doc":
                            var faClass = "fa-file-text-o";
                            break;
                        case "pdf":
                            var faClass = "fa-file-pdf-o";
                            break;
                        default:
                            var faClass = "fa-file-o";
                    }
                    // icon wrapper
                    $("<span/>", {
                        class: "icon"
                    }).appendTo($(this));

                    // icon
                    $("<i/>", {
                        class: "fa fa-2x " + faClass,
                        "aria-hidden": true
                    }).appendTo($(this).find("span.icon"));

                    // text wrapper
                    $("<span/>", {
                        class: "text"
                    }).appendTo($(this));

                    // title
                    $("<span/>", {
                        class: "title",
                        text: titleText
                    }).appendTo($(this).find("span.text"));

                    // description
                    $("<span/>", {
                        class: "desc",
                        text: descText
                    }).appendTo($(this).find("span.text"));
                } else {
                    var faClass = "fa-file-o";

                    // icon wrapper
                    $("<span/>", {
                        class: "icon"
                    }).appendTo($(this));

                    // icon
                    $("<i/>", {
                        class: "fa fa-2x " + faClass,
                        "aria-hidden": true
                    }).appendTo($(this).find("span.icon"));

                    // text wrapper
                    $("<span/>", {
                        class: "text"
                    }).appendTo($(this));

                    // title
                    $("<span/>", {
                        class: "title",
                        text: titleText
                    }).appendTo($(this).find("span.text"));

                    // description
                    $("<span/>", {
                        class: "desc",
                        text: descText
                    }).appendTo($(this).find("span.text"));
                }
            }
        });
    }

    // LOKERESO
    var horseFinderForm = $(".horse-search-form");

    if (horseFinderForm) {
        var resultsContainer = $(".results-container .list-group");
        var keywordInput = horseFinderForm.find(".keyword-input");
        var checkboxes = horseFinderForm.find(".checkbox-input");
        var ajaxCall = function() {
            var keyword = $.trim(keywordInput.val());
            var checkedboxes = horseFinderForm.find(".checkbox-input:checked");
            var checkboxParameters = [];
            if (checkedboxes.length > 0) {
                checkedboxes.each(function() {
                    checkboxParameters.push($(this).val());
                });
            }
            var parameters = "<div>";
            if (keyword !== "") {
                parameters += "<p>Keyword: " + keyword + "</p>";
            }

            if (checkboxParameters.length > 0) {
                parameters += "<p>Checked filters:";
                for (var i = 0, l = checkboxParameters.length; i < l; i++) {
                    parameters += checkboxParameters[i] + ", ";
                }
                parameters += "</p>";
            }
            parameters += "</div>";
            $.ajax({
                method: "POST",
                url: "api/horseSearch",
                data: {
                    _token: $('meta[name="csrf-token"]').attr("content"),
                    q: keyword,
                    p: checkboxParameters
                },
                success: function(result) {
                    //console.log(result);
                    var timestamp = "<p>Timestamp: " + $.now() + "</p>";
                    //var content = timestamp + parameters;
                    var content = "";
                    var url = $(".results-container .list-group").data("horse_data_url");
                    $.each(result.horses, function(index, value) {
                        var event = new Date(
                            Date.UTC(
                                value.szulDatumEv,
                                value.szulDatumHo - 1,
                                value.szulDatumNap,
                                3,
                                0,
                                0
                            )
                        );

                        var options = { year: "numeric", month: "long", day: "numeric" };
                        let szuletesnap = event.toLocaleDateString("hu-HU", options);
                        content +=
                            '<a href="' +
                            url +
                            "/" +
                            value.loId +
                            '" target="_blank" class="list-group-item">' +
                            '<h4 class="list-group-item-heading">' +
                            value.nev +
                            "</h4>" +
                            '<p class="list-group-item-text">' +
                            value.nem +
                            " | " +
                            value.szin +
                            " | " +
                            szuletesnap +
                            "</p>" +
                            "</a>";
                    });
                    if (result.parents["anya"] != undefined) {
                        if (result.parents.anya.length > 0) {
                            content += "<h2>Szülőként szerepel (Anya)</h2>";
                            $.each(result.parents.anya, function(index, value) {
                                var event = new Date(
                                    Date.UTC(
                                        value.szulDatumEv,
                                        value.szulDatumHo - 1,
                                        value.szulDatumNap,
                                        3,
                                        0,
                                        0
                                    )
                                );
                                let anya = $.parseJSON(value.anya);
                                var options = {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric"
                                };
                                let szuletesnap = event.toLocaleDateString("hu-HU", options);
                                content +=
                                    '<a href="' +
                                    url +
                                    "/" +
                                    value.loId +
                                    '" target="_blank" class="list-group-item">' +
                                    '<h4 class="list-group-item-heading">' +
                                    value.nev +
                                    "</h4>" +
                                    '<p class="list-group-item-text">(Anya:' +
                                    anya.nev +
                                    " ) " +
                                    value.nem +
                                    " | " +
                                    value.szin +
                                    " | " +
                                    szuletesnap +
                                    "</p>" +
                                    "</a>";
                            });
                        }
                    }
                    if (result.parents["apa"] != undefined) {
                        if (result.parents.apa.length > 0) {
                            content += "<h2>Szülőként szerepel (Apa)</h2>";
                            $.each(result.parents.apa, function(index, value) {
                                var event = new Date(
                                    Date.UTC(
                                        value.szulDatumEv,
                                        value.szulDatumHo - 1,
                                        value.szulDatumNap,
                                        3,
                                        0,
                                        0
                                    )
                                );
                                let apa = $.parseJSON(value.apa);
                                var options = {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric"
                                };
                                let szuletesnap = event.toLocaleDateString("hu-HU", options);
                                content +=
                                    '<a href="' +
                                    url +
                                    "/" +
                                    value.loId +
                                    '" target="_blank" class="list-group-item">' +
                                    '<h4 class="list-group-item-heading">' +
                                    value.nev +
                                    "</h4>" +
                                    '<p class="list-group-item-text">(Apa:' +
                                    apa.nev +
                                    " ) " +
                                    value.nem +
                                    " | " +
                                    value.szin +
                                    " | " +
                                    szuletesnap +
                                    "</p>" +
                                    "</a>";
                            });
                        }
                    }
                    $(resultsContainer).html(content);
                }
            });
        };
        keywordInput.on("keyup", function() {
            if ($.trim(keywordInput.val()).length > 2) {
                ajaxCall();
            }
        });
        checkboxes.on("change", function() {
            ajaxCall();
        });
    }
});
